import React, {Fragment, useEffect, useState} from 'react';
import camelcaseKeys from 'camelcase-keys-recursive';
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DownloadIcon from '@material-ui/icons/GetApp';
import ArrowDropDownCircleOutlinedIcon from '@material-ui/icons/ArrowDropDownCircleOutlined';
import Header from "../header/Header";
import CSS from "./ConfigurationManagementPage.module.scss";
import axios from '../../utils/axios';
import { connect } from "react-redux";
import {
	Icon,
	Table,
	Segment,
  Dropdown,
  Input,
  Modal,
  Button,
	Checkbox
} from "semantic-ui-react";
import {
	useTable,
	usePagination,
	useSortBy,
	useExpanded,
	useGlobalFilter,
	useFilters, 
} from "react-table";
import { useTranslation } from "react-i18next";
import { getConfigurations, createConfig, updateConfig } from "../../redux/configuration/actions";
import { getFuneralHomes } from "../../redux/funeralHome/actions";

const CONFIGURATION_DATA = {
  funeralHomeId: '',
  roomId: '',
  ec2InstanceId: '',
  wanIp: '',
  lanIp: '',
	clientAddress: '',
	publicKey: '',
	privateKey: '',
	routerIp: '',
  gstreamerFrom: '',
  gstreamerTo: '',
  routerUser: 'user',
  routerPassword: 'p@ssword!1',
	encoderStream: false,
};

const ConfigurationManagementPage = ({configurations, funeralHomes, getConfigurations, user, getFuneralHomes, createConfig, updateConfig}) => {
  const [open, setOpen] = useState(false);
	useEffect(()=> {
		getConfigurations();
	},[]);
	const { t } = useTranslation();
	const columns = [
		{
			Header: 'Funeral Home',
			accessor: (config)=>{
				return (<div>{config.funeralHome.name}</div>);
			},
			minWidth:180,
			width:180,
			sortType: (rowA, rowB) => {
				if (
					rowA.original.name.toLocaleLowerCase() <
					rowB.original.name.toLocaleLowerCase()
				)
					return -1;
				if (
					rowA.original.name.toLocaleLowerCase() >
					rowB.original.name.toLocaleLowerCase()
				)
					return 1;
				return 0;
			},
		},
		{
			Header: 'Room',
			accessor: (config)=> config.room?.name,
			minWidth:180,
			width:180,
			disableGlobalFilter: true,
		},	
    {
			Header: 'EC2 Instance ID',
			accessor: (config)=> config.ec2InstanceId,
			minWidth:180,
			width:180,
			disableGlobalFilter: true,
    },	
    {
			Header: 'LAN IP',
			accessor: (config)=> config.lanIp,
			minWidth:180,
			width:180,
			disableGlobalFilter: true,
    },	
    {
			Header: 'WAN IP',
			accessor: (config)=> config.wanIp,
			minWidth:180,
			width:180,
			disableGlobalFilter: true,
    },	
    {
			Header: 'Router User',
			accessor: (config)=> config.routerUser,
			minWidth:180,
			width:180,
			disableGlobalFilter: true,
    },	
    {
			id: 'delete',
			Header: () => {return <ArrowDropDownCircleOutlinedIcon/>},
			accessor: (config) => {
				return (
					<>
						<DownloadIcon onClick={() => handleDownload(config.id)}/>
						<DeleteForeverIcon onClick={() => handleDelete(config.id, getConfigurations)}/>
					</>
				)
			},
			width:40,
			disableSortBy: true,
			disableGlobalFilter: true,
			// style:{
			// 	textAlign: 'right'
			// }
		}
	];
    return (
      <div>
        <Header/>
        <div className={CSS.bodyWrapper}> 
          <section className={CSS.formWrapper}>
            <span className={CSS.pageTitlte}>
            Conifugrations
            </span>
            <Segment style={{padding: "0px", border: "1px solid #C4D5D9", borderRadius: "8px", overflow: "hidden"}}>
              {configurations && 
              <OrgTable 
                setOpen={setOpen} 
                open={open} 
                data={configurations} 
                columns={columns} 
                user={user}
                getFuneralHomes={getFuneralHomes}
                funeralHomes={funeralHomes}
								createConfig={createConfig}
								updateConfig={updateConfig}
                getConfigurations={getConfigurations}
								t={t}
              />
            }
            </Segment>
          </section>
        </div>
      </div>
    )
}

const handleModalOpen = (setOpen, setIsCreate, setConfigurationData, getFuneralHomes) => {
	setOpen(true);
	setIsCreate(true);
	setConfigurationData(CONFIGURATION_DATA);
  getFuneralHomes();
}

const updateRoomIdAndGetEc2Instances = async (value, configurationData, setConfigurationData, ec2Instances, setEc2Instances) => {
  setConfigurationData({ ...configurationData, roomId: value });
  if (ec2Instances.length === 0) {
    const response = await axios.get('api/configurations/ec2_instances', {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("token")}`
      }
    });
    const instances = camelcaseKeys(response.data).ec2Instances;
    setEc2Instances(instances);
  }
}

const updateSelectedEc2Instance = async (value, configurationData, ec2Instances, setAvailableLans, setConfigurationData) => {
  const instance = ec2Instances.find((instance) => instance.id === value);
  const availableLansResponse = await axios.get(`api/configurations/get_available_lans?ec2_instance_id=${value}`, {
    headers: {
      "Authorization": `Bearer ${localStorage.getItem("token")}`
    }
  });
	const availableLans = camelcaseKeys(availableLansResponse.data).availableLans;
	
	setAvailableLans(availableLans);

  setConfigurationData({ 
    ...configurationData, 
    ec2InstanceId: value,
    wanIp: instance.ip,
  });
}

const updateSelectedLanIp = async (value, configurationData, availableLans, setConfigurationData) => {
	const lan = availableLans.find((availableLan) => availableLan.ip === value);	
	const cameraIpResponse = await axios.get(`api/configurations/get_next_available_camera_ip?ec2_instance_id=${configurationData.ec2InstanceId}&lan_ip=${value}`, {
    headers: {
      "Authorization": `Bearer ${localStorage.getItem("token")}`
    }
  });
  const cameraIp = camelcaseKeys(cameraIpResponse.data).cameraIp;
	
	setConfigurationData({ 
    ...configurationData, 
    lanIp: lan.ip,
		routerUser: lan.user,
		routerPassword: lan.password,
		gstreamerFrom: cameraIp,
		routerIp: cameraIp
  });
}

const updateGstreamerFrom = async (value, configurationData, setConfigurationData) => {
  const cameraIpResponse = await axios.get(`api/configurations/get_next_available_camera_ip?ec2_instance_id=${configurationData.ec2InstanceId}&lan_ip=${value}`, {
    headers: {
      "Authorization": `Bearer ${localStorage.getItem("token")}`
    }
  });
  const cameraIp = camelcaseKeys(cameraIpResponse.data).cameraIp;

  setConfigurationData({ 
    ...configurationData,
    gstreamerFrom: cameraIp,
  });
}

const updateRouterIp = async (value, configurationData, setConfigurationData) => {
  const cameraIpResponse = await axios.get(`api/configurations/get_next_available_camera_ip?ec2_instance_id=${configurationData.ec2InstanceId}&lan_ip=${value}`, {
    headers: {
      "Authorization": `Bearer ${localStorage.getItem("token")}`
    }
  });
  const cameraIp = camelcaseKeys(cameraIpResponse.data).cameraIp;

  setConfigurationData({ 
    ...configurationData,
    routerIp: cameraIp,
  });
}

const handleDelete = async (id, getConfigurationsAction) => {
  await axios.delete(`api/configurations/${id}`, {
    headers: {
      "Authorization": `Bearer ${localStorage.getItem("token")}`
    }
	});
	getConfigurationsAction();
}

const handleDownload = async (id) => {
	const response = await axios.get(`api/configurations/${id}/router_config`, {
    headers: {
      "Authorization": `Bearer ${localStorage.getItem("token")}`
    }
	});
	const element = document.createElement("a");
	const file = new Blob([response.data], {
		type: "text/plain"
	});
	element.href = URL.createObjectURL(file);
	element.download = `${id}_configuration.rsc`;
	document.body.appendChild(element);
	element.click();
}

const handleCreateNewInstance = async(setEc2Instances) => {
  await axios.post('api/configurations/ec2_instances', {}, {
    headers: {
      "Authorization": `Bearer ${localStorage.getItem("token")}`
    }
  });

  const response = await axios.get('api/configurations/ec2_instances', {
    headers: {
      "Authorization": `Bearer ${localStorage.getItem("token")}`
    }
  });
  const instances = camelcaseKeys(response.data).ec2Instances;
  setEc2Instances(instances);
}

const handleCreateOrUpdateConfig = async (configurationData, setOpen, setConfigurationData, createConfig, updateConfig, getConfigurations) => {
	if (!configurationData.id) {
		await createConfig(configurationData);
	} else {
		await updateConfig(configurationData.id, configurationData);
	}
  setConfigurationData(CONFIGURATION_DATA);
  setOpen(false);
  getConfigurations();
}

const OrgTable = ({ columns, data, user, setOpen, open, getFuneralHomes, funeralHomes, createConfig, updateConfig, getConfigurations, t }) => {
	const [hoveredRow, setHoveredRow] = useState(null);
	const [isCreate, setIsCreate] = useState(true);
  const [configurationData, setConfigurationData] = useState(CONFIGURATION_DATA);
	const [ec2Instances, setEc2Instances] = useState([]);
	const [availableLans, setAvailableLans] = useState([]);
	useEffect(() => {
	}, [hoveredRow]);
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		canPreviousPage,
		canNextPage,
		pageOptions,
		nextPage,
		previousPage,
		setPageSize,
		state,
	} = useTable(
		{
			columns,
			data,
			globalFilter: (rows, columnIds, globalFilterData) => {
				return rows.filter((row) => {
				const event = row.original;
         		let {filterValue} = globalFilterData;
				if(!(filterValue && filterValue.trim())){
					return true;
				}
				filterValue = filterValue.trim().toLowerCase();
				return (
					event.name
						.toLowerCase()
						.includes(filterValue) ||
					`${event.name.toLowerCase()}`.includes(
						filterValue
					)
				);
				});
			},
			initialState: { pageIndex: 0 },
		},
		useGlobalFilter,
		useFilters,
		useSortBy,
		useExpanded,
		usePagination
	);

	async function handleRowClick(configuration) {
		await getFuneralHomes();
		setConfigurationData({
			id: configuration.id,
			roomId: configuration.roomId,
			funeralHomeId: configuration.funeralHomeId,
			wanIp: configuration.wanIp,
			lanIp: configuration.lanIp,
			publicKey: configuration.publicKey,
			privateKey: configuration.privateKey,
			clientAddress: configuration.clientAddress,
			routerIp: configuration.routerIp,
			gstreamerFrom: configuration.gstreamerFrom,
			gstreamerTo: configuration.gstreamerTo,
			routerPassword: configuration.routerPassword,
			routerUser: configuration.routerUser,
			ec2InstanceId: configuration.ec2InstanceId,
			encoderStream: configuration.encoderStream,
		});
		if (ec2Instances.length === 0) {
			const response = await axios.get('api/configurations/ec2_instances', {
				headers: {
					"Authorization": `Bearer ${localStorage.getItem("token")}`
				}
			});
			const instances = camelcaseKeys(response.data).ec2Instances;
			setEc2Instances(instances);
		}
		setIsCreate(false);
		setOpen(true);
	}

	return (
		<Fragment>
			<div className={CSS.configTableHeader} style={{margin: "16px 16px 0px 16px"}}> 
				{
          user.user && (user.user?.role === "admin" || user.user?.role === "super_admin") &&
          <Modal
            onClose={() => setOpen(false)}
            onOpen={() => handleModalOpen(setOpen, setIsCreate, setConfigurationData, getFuneralHomes)}
            open={open}
            trigger={<button className={`${CSS.actionBtn} ${CSS.warning}`}>Create Configuration</button>}
          >
            <Modal.Header>Setup Configuration</Modal.Header>
            <Modal.Content>
              <Modal.Description>
                <Dropdown
                  onChange={ (e, { value }) => setConfigurationData({ ...configurationData, funeralHomeId: value })}
                  options={funeralHomes.map((funeralHome, index) => ({ key: index, text:funeralHome.name, value: funeralHome.id }))}
                  placeholder='Choose Funeral Home'
                  value={configurationData.funeralHomeId}
                />
                { configurationData.funeralHomeId && 
                  <Dropdown
                    onChange={ (e, { value }) => updateRoomIdAndGetEc2Instances(value, configurationData, setConfigurationData, ec2Instances, setEc2Instances)}
                    options={funeralHomes.find((fh) => fh.id === configurationData.funeralHomeId).rooms.map((room, index) => ({ key: index, text:room.name, value: room.id }))}
                    placeholder='Choose Room'
                    value={configurationData.roomId}
                  />
                }
                <div>
                  { ec2Instances.length > 0 && 
                    <Dropdown
                      onChange={ (e, { value }) => updateSelectedEc2Instance(value, configurationData, ec2Instances, setAvailableLans, setConfigurationData)}
                      options={ec2Instances.map((instance, index) => ({ key: index, text: `${instance.id} (${instance.state})`, value: instance.id }))}
                      placeholder='Choose a Running EC2 Instance'
                      value={configurationData.ec2InstanceId}
                    />
                  } 
                  {
                    ec2Instances.length < 5 && 
                    <Button disabled={!configurationData.roomId} onClick={() => handleCreateNewInstance(setEc2Instances)}>
                      Create New Instance
                    </Button>
                  }
                </div>
								<Dropdown
									onChange={ (e, { value }) => updateSelectedLanIp(value, configurationData, availableLans, setConfigurationData)}
									options={availableLans.map((lan, index) => ({ key: index, text: `${lan.ip}`, value: lan.ip }))}
									placeholder='Choose LAN IP'
									value={configurationData.lanIp}
								/>
                <div style={{marginTop: 50}}>
									<div style={{ margin: 5 }}>
										<label style={{ marginRight: 5 }}>LAN IP</label>
										<Input
											onChange={ (e) => setConfigurationData({ ...configurationData, lanIp: e.target.value })}
											placeholder='Set LAN IP'
											value={configurationData.lanIp}
											onBlur={(e) => {updateGstreamerFrom(e.target.value, configurationData, setConfigurationData); updateRouterIp(e.target.value, configurationData, setConfigurationData)}}
											disabled
										/>
									</div>
									<div style={{ margin: 5 }}>
										<label style={{ marginRight: 5 }}>Router User</label>
										<Input
											onChange={ (e) => setConfigurationData({ ...configurationData, user: e.target.value })}
											placeholder='Router User'
											value={configurationData.routerUser}
											disabled
										/>
									</div>
									<div style={{ margin: 5 }}>
										<label style={{ marginRight: 5 }}>Router Password</label>
										<Input
											onChange={ (e) => setConfigurationData({ ...configurationData, password: e.target.value })}
											placeholder='Router Password'
											value={configurationData.routerPassword}
											disabled
										/>
									</div>
                </div>
								<div style={{ margin: 5 }}>
									<label style={{ marginRight: 5 }}>Router IP Address</label>
                  <Input
                    onChange={ (e) => setConfigurationData({ ...configurationData, routerIp: e.target.value })}
                    placeholder='Router IP Address - set before the file generation'
                    value={configurationData.routerIp}
                  />
                </div>
                <div style={{ margin: 5 }}>
									<label style={{ marginRight: 5 }}>Gstreamer From (Camera or Encoder IP)</label>
                  <Input
                    onChange={ (e) => setConfigurationData({ ...configurationData, gstreamerFrom: e.target.value })}
                    placeholder='Get from the router and see what is connected'
                    value={configurationData.gstreamerFrom}
                  />
                </div>
								<div style={{ margin: 5 }}>
									<label style={{ marginRight: 5 }}>Public Key</label>
                  <Input
										type="text"
										rows="5"
                    onChange={ (e) => setConfigurationData({ ...configurationData, publicKey: e.target.value })}
                    placeholder='Run "wg show all" in EC2 instance'
                    value={configurationData.publicKey}
										style={{ width: 500 }}
                  />
                </div>
								<div style={{ margin: 5 }}>
									<label style={{ marginRight: 5 }}>Private Key</label>
                  <Input
										type="text"
                    onChange={ (e) => setConfigurationData({ ...configurationData, privateKey: e.target.value })}
                    placeholder='(From EC2 instance vim /etc/wireguard)'
                    value={configurationData.privateKey}
										style={{ width: 500 }}
                  />
                </div>
								<div style={{ margin: 5 }}>
									<label style={{ marginRight: 5 }}>Client Address</label>
                  <Input
										type="text"
                    onChange={ (e) => setConfigurationData({ ...configurationData, clientAddress: e.target.value })}
                    placeholder='(From EC2 instance /etc/wireguard/wg0.cfg)'
                    value={configurationData.clientAddress}
										style={{ width: 500 }}
                  />
                </div>
								<div style={{ margin: 5 }}>
									<label style={{ marginRight: 5 }}>Gstreamer To (Comma Separated)</label>
										<Input
											onChange={ (e) => setConfigurationData({ ...configurationData, gstreamerTo: e.target.value })}
											placeholder='Gstreamer To'
											value={configurationData.gstreamerTo}
										/>
								</div>
								<div style={{ margin: 5 }}>
									<label style={{ marginRight: 5 }}>Encoder Stream</label>
										<Checkbox 
											toggle 
											onChange={(e, data) => setConfigurationData({ ...configurationData, encoderStream: data.checked })}
											checked={configurationData.encoderStream}
										/>
								</div>
								<h4>Current Configuration</h4>
                <pre>
                  <code>
                    {JSON.stringify(configurationData, null, 4)}
                  </code>
                </pre>
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button color='black' onClick={() => setOpen(false)}>
                Cancel
              </Button>
              <Button
                onClick={() => handleCreateOrUpdateConfig(configurationData, setOpen, setConfigurationData, createConfig, updateConfig, getConfigurations)}
                positive>
                {isCreate ? "Create" : "Update"}
              </Button>
            </Modal.Actions>
          </Modal>
        }
			</div>
			<Table className={CSS.table} structured {...getTableProps()} style={{border : "none"}}>
				<Table.Header>
				{headerGroups.map((headerGroup) => (
					<Table.Row {...headerGroup.getHeaderGroupProps()}>
						{headerGroup.headers.map((column) => (
							<Table.HeaderCell
								className={CSS.headerCell}
								{...column.getHeaderProps({
									...column.getSortByToggleProps(),
									style: {minWidth: column.minWidth, width: column.width}
								})}>
								{column.render("Header")}
								<span>
									{column.isSorted ? (
										column.isSortedDesc ? (
											<Icon name="caret down" />
										) : (
											<Icon name="caret up" />
										)
									) : (
										""
									)}
								</span>
							</Table.HeaderCell>
						))}
					</Table.Row>
				))}
			</Table.Header>
				<Table.Body {...getTableBodyProps()}>
					{page.length === 0 &&
						(state.filters.length > 0 || state.globalFilter) && (
							<Table.Row>
								<Table.Cell></Table.Cell>
								<Table.Cell style={{
										padding: "50px 0 50px 125px",
										fontStyle: "italic",
									}}>{t("pages.admin_create_org.table.filter_no_results")}</Table.Cell>
								<Table.Cell></Table.Cell>
								<Table.Cell></Table.Cell>
							</Table.Row>
						)}
					{page.map((row, i) => {
						prepareRow(row);
						const rowProps = row.getRowProps();
						return (
							<React.Fragment key={rowProps.key}>
								<Table.Row {...rowProps}
									onMouseEnter={()=> setHoveredRow(rowProps.key)}
									onMouseLeave={() => setHoveredRow(null)}
									onClick={() => handleRowClick(row.original)}
									style={{ background: rowProps.key === hoveredRow ? '#D6FFD6' : 'white', }}>
										{row.cells.map((cell) => {
											return (
												<Table.Cell
													{...cell.getCellProps()}>
													{cell.render("Cell")}
												</Table.Cell>
											);
										})}
								</Table.Row>
							</React.Fragment>
						);
					})}
				</Table.Body>
			</Table>
			<div className={CSS.pagination} style={{borderBottomRightRadius: "8px", borderBottomLeftRadius: "8px"}}>
				<div className={CSS.pageDropdown}>
					{'Rows per page'}:
					<Dropdown
						value={state.pageSize}
						options={[
							{ key: 1, text: "10", value: 10 },
							{ key: 2, text: "20", value: 20 },
							{ key: 3, text: "30", value: 30 },
							{ key: 4, text: "40", value: 40 },
							{ key: 5, text: "50", value: 50 },
						]}
						onChange={(e, data) => {
							setPageSize(Number(data.value));
						}}
					/>
				</div>
				<div className={CSS.pageMover}>
					<Icon
						name="arrow left"
						onClick={() => previousPage()}
						disabled={!canPreviousPage}
					/>{" "}
					<span>
					{t("pages.admin_create_org.table.page")}{" "}
						<strong>
							{state.pageIndex + 1} of {pageOptions.length}
						</strong>{" "}
					</span>{" "}
					<Icon
						name="arrow right"
						onClick={() => nextPage()}
						disabled={!canNextPage}
					/>
				</div>
			</div>
		</Fragment>	
	)
}

const mapStateToProps = (state) => ({
  configurations: state.configuration.configurations,
  funeralHomes: state.funeralHome.funeralHomes,
  user: state.user.currentUser,
});

const mapDispatchToProps = {
  getConfigurations,
  getFuneralHomes,
	createConfig,
	updateConfig
};
export default connect(mapStateToProps, mapDispatchToProps)(ConfigurationManagementPage);
